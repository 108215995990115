// Base Definitions
$body-bg: #ffff;
$solid-black: #2C2826;
$solid-purple: #AB24FF;

$primary: #EC6114;
$secondary: #0098C3;
$font-family-base: Runda;
$font-weight-base: 400;
$headings-font-weight: 400;

// Navbar
.navbar {
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.06);
}

.nav-link {
  color: $solid-black;
  font-size: 16px;
  font-weight: 500;
}

// Button Definitions
$btn-border-radius: 80px;
$btn-border-radius-sm: 46px;
$btn-padding-x: 60px;
$btn-padding-y: 18px;

.btn.btn-sm.btn-primary {
  color: white;
  background-color: $primary;
  border: none;
}

.btn.btn-sm {
  font-size: 14px;
  border: 2px solid #E0E0E0;
  background-color: transparent;
  color: $solid-black;
  padding: 10px 20px;
}

// Card
$card-border-radius: 4px;
$card-border-color: transparent;
$card-bg: #FFFF;
.card {
  box-shadow: 0 0 30px 8px rgba(0, 0, 0, 0.06);
}

.card.card-activity {
  width: 344px;
  display: inline-block;
}

.card.card-packet {
  width: 344px;
  display: inline-block;
  transition: all ease-in-out 0.2s;
}

a .card.card-packet {
  color: $solid-black;
  margin-bottom: 20px;
}

.card.card-packet:hover {
  box-shadow: 0 0 30px 20px rgba(0, 0, 0, 0.06);

  .card-title {
    color: $solid-purple;
  }

}

// Modal
$modal-header-border-color: transparent;

.modal-header {
  padding: 5px 10px;
}

.modal-header .close {
  margin: 0;
}

button.close {
  border-radius: 300px;
  border: 2px solid #BDBDBD;
  box-sizing: border-box;
  height: 48px;
  width: 48px;
  padding-top: 8px !important;
  margin: 0;
}

// Input
$input-bg: white;
$input-focus-border-color: #E4B8FF;
$input-border-color: #E0E0E0;
$input-border-radius: 0;
$input-focus-box-shadow: none;

label {
  font-size: 18px;
  font-weight: 500;
}

input.form-control {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom-width: 2px;
}

input.form-control:focus {
  border-top: none;
  border-left: none;
  border-right: none;
}

textarea.form-control {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom-width: 2px;
}

// Typography

h1 {
  font-weight: normal !important;
  font-size: 48px !important;
}

h2 {
  font-size: 32px !important;
  font-weight: 400 !important;
}

h3 {
  font-size: 24px !important;
}

h4 {
  font-weight: 500 !important;
  font-size: 18px !important;
}

h5 {
  font-weight: 500 !important;
  font-size: 14px !important;
}

