@import "variables";
@import "~bootstrap/scss/bootstrap";

body {
  margin: 0;
  font-family: -apple-system, $font-family-base, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


html, body {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1 0 auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1 0 auto;
}

#footer {
  width: 100%;
  background-color: $solid-black;
  flex-shrink: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 52px 296px 20px 296px;
}

// Masonry Grid
.masonry-grid {
  columns: 3 200px;
  grid-column-gap: 1rem;
  column-gap: 5rem;
}


// Profile
.profile-picture {
  width: 32px;
  height: 32px;
  background-position: center;
  background-size: cover;
  border-radius: 80px;
}

.profile-name {
  color: $primary;
}

// Pages
.page-title {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

// Divider
.divider {
  margin: 16px 0;
  border: 2px solid #E0E0E0
}
.divider.yellow {
  border-color: #FED100;
}
.divider.purple {
  border-color: $solid-purple;
}

// Tag
.tag {
  background-color: transparent;
  color: $solid-black;
  padding: 8px 16px;
  border-radius: 33px;
  cursor: pointer;
  border: 2px solid #E0E0E0;
  display: inline-block;
}

.tag.primary {
  border-color: $secondary;
}

.tag .tag-content {
  display: flex;
  width: fit-content;
  width: -moz-fit-content;
  flex-direction: row;
  align-items: center;
}

.tag.active {
  background-color: $secondary;
  color: white;
}

// SVG
path {
  fill: $primary;
}

// Fileupload
.btn-upload {
  position: relative;
  overflow: hidden;
  direction: ltr;
}

input[type="file"].file-upload {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 118px;
  margin: 0;
  padding: 0;
  cursor: pointer;
  opacity: 0;
  height: 100%;
}